import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Empty,
  Icon,
  Input,
  Menu,
  message,
  Modal,
} from 'antd';
import { saveAs } from 'file-saver';
import {
  flatten,
  get,
  sortBy,
} from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { connect } from 'react-redux';

import { RouteComponentProps } from '@reach/router';

import {
  ContainerLoading,
  ReviewCard,
  ReviewDetails,
} from '../../components';
import {
  addNoteToReview,
  getAverageTurnaroundTime,
  getUnhappyCustomerReviews,
  markReviewAs,
  RootState,
} from '../../redux';
import {
  AverageTurnaroundTime,
  BusinessProfileType,
  ResolutionStageType,
  ReviewRepo,
  ReviewType,
} from '../../repos';
import { Colors } from '../../theme';
import { SectionListData } from '../../utils';
import AverageResolutionTimeCard from './AverageResolutionTimeCard';
import AverageResponseTimeCard from './AverageResponseTimeCard';

interface UnhappyCustomersContainerProps {
  currentListingUid?: string;
  loading?: boolean;
  unhappyCustomerReviews?: {
    resolved: SectionListData<ReviewType>[];
    unresolved: SectionListData<ReviewType>[];
  };
  businessProfile?: BusinessProfileType;
  employeeListings?: {
    [key: string]: string;
  };
  getUnhappyCustomerReviews: typeof getUnhappyCustomerReviews;
  getAverageTurnaroundTime: typeof getAverageTurnaroundTime;
  addNoteToReview: typeof addNoteToReview;
  markReviewAs: typeof markReviewAs;
  averageTurnaroundTime?: AverageTurnaroundTime;
}

enum UnhappyCustomerTabs {
  Resolved = 'Resolved',
  Unresolved = 'Unresolved',
}
const UnhappyCustomersContainer: React.SFC<
  RouteComponentProps<UnhappyCustomersContainerProps>
> = props => {
  const {
    currentListingUid,
    getUnhappyCustomerReviews,
    getAverageTurnaroundTime,
    addNoteToReview,
    markReviewAs,
    unhappyCustomerReviews,
    loading,
    businessProfile,
    averageTurnaroundTime,
    employeeListings,
  } = props;

  const [selectedTab, setSelectedTab] = useState(UnhappyCustomerTabs.Unresolved);
  const [addNotesPopupReviewData, setAddNotesPopupReviewData] = useState<{
    reviewUid?: string;
    stage?: ResolutionStageType;
  }>();
  const [selectedReview, setSelectedReview] = useState<ReviewType>();
  const [note, setNote] = useState<string>();
  const [processingDownload = false, setProcessingDownload] = useState<boolean>();
  const [unresolvedReviewFilters, setUnresolvedReviewFilters] = useState<{
    [k: string]: boolean;
  }>();

  // prettier-ignore
  const [
    selectedDates = [moment().startOf('day'), moment().endOf('day')],
    setSelectedDates,
  ] = useState<[Moment, Moment]| undefined>();

  useEffect(() => {
    if (!getUnhappyCustomerReviews || !getAverageTurnaroundTime) {
      return;
    }
    if (businessProfile) {
      setUnresolvedReviewFilters(
        businessProfile.resolutionStages.stages.reduce(
          (acc, stage) => {
            acc[stage.name] = true;
            return acc;
          },
          {} as { [k: string]: boolean },
        ),
      );
    }
    const [startDate, endDate] = selectedDates;
    getUnhappyCustomerReviews(startDate, endDate);
    getAverageTurnaroundTime(startDate, endDate);
  }, [currentListingUid, selectedDates[0].format('DD MM YY'), selectedDates[1].format('DD MM YY')]);

  const onSubmit = (reviewUid: string, stage: ResolutionStageType) => {
    if (!note || note.length === 0) {
      message.error('Please enter a valid text');
      return;
    }
    markReview(reviewUid, stage);
    addNoteToReview && addNoteToReview(reviewUid, note);
    setNote('');
    setAddNotesPopupReviewData({ reviewUid: undefined, stage: undefined });
  };

  const markReview = (reviewUid: string, stage: ResolutionStageType) => {
    markReviewAs && stage && markReviewAs(reviewUid, stage);
  };

  /**
   * Using file-saver to create and download a file requires some tweaking around encoding of file.
   * To understand it more please go to:
   * https://github.com/eligrey/FileSaver.js/issues/28
   */
  const downloadData = async () => {
    if (!unhappyCustomerReviews) {
      return;
    }

    message.info(
      <Button type="ghost" style={{ border: 'none' }} loading>
        Processing
      </Button>,
      0,
    );
    setProcessingDownload(true);

    const BOM = '\uFEFF';
    const reviewsFlattened: ReviewType[] = flatten(
      unhappyCustomerReviews.resolved
        .concat(unhappyCustomerReviews.unresolved)
        .map(sections => sections.data),
    );

    const reviewsAndRemarks: (ReviewType & { remarks?: string })[] = [];

    for (const review of reviewsFlattened) {
      const remarks = await ReviewRepo.getUserReviewNotes(review.uid).then(remarks => {
        if (remarks.length === 0) {
          return undefined;
        }
        return remarks
          .map(
            remark =>
              `[${remark.createdAt.format('DD-MM-YY hh:mm a')}] ${remark.employeeName}: ${
                remark.description
              }`,
          )
          .join('\n');
      });
      reviewsAndRemarks.push({ ...review, remarks });
    }
    const data = sortBy(reviewsAndRemarks, [review => review.createdAt.unix()]).map(review => {
      return [
        review.reviewerName || '', // Reviewer Name
        review.reviewerMobileNumber || '', // Reviewer Mobile Number
        review.rating || '', // Review rating
        (review.comment && `"${review.comment}"`) || '', // Review comments
        (review.remarks && `"${review.remarks}"`) || '', // Review Remarks
        review.createdAt.format(), // Review requested on
        review.updatedAt.format(), // Reviewed on
        review.resolved ? 'Yes' : 'No', // Is Resolved?
        review.resolved ? review.updatedAt.format() : '', // Resolved On
        employeeListings ? `"${employeeListings[review.listingUid]}"` : '', // Centre Name
      ].join(',');
    });
    const headers = [
      'Name',
      'Mobile Number',
      'Rating',
      'Comment',
      'Remarks',
      'Request Date',
      'Review Date',
      'Is it resolved?',
      'Resolved On',
      'Centre',
    ];
    data.unshift(headers.join(','));
    const csv = data.join('\r\n');

    const blob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8' });
    const fileName = `${selectedDates.map(date => date.format('DD-MM-YYYY')).join('-')}.csv`;
    saveAs(blob, fileName);

    message.destroy();
    setProcessingDownload(false);
  };

  // Render methods

  function renderTurnaroundTimeCards() {
    if (
      !averageTurnaroundTime ||
      (averageTurnaroundTime.averageResolutionTime <= 0 &&
        averageTurnaroundTime.averageResponseTime <= 0)
    ) {
      return undefined;
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          width: '100%',
          maxWidth: 1024,
          margin: 10,
          backgroundColor: Colors.Grey100,
        }}>
        {averageTurnaroundTime.averageResolutionTime > 0 && (
          <div
            style={{
              marginRight: 15,
              width: '50%',
              boxShadow: '1px 1px 8px #d0d0d0',
              backgroundColor: Colors.White100,
            }}>
            <AverageResolutionTimeCard />
          </div>
        )}
        {averageTurnaroundTime.averageResponseTime > 0 && (
          <div
            style={{
              marginLeft: 15,
              width: '50%',
              boxShadow: '1px 1px 8px #d0d0d0',
              backgroundColor: Colors.White100,
            }}>
            <AverageResponseTimeCard />
          </div>
        )}
      </div>
    );
  }

  const renderReviews = () => {
    if (loading) {
      return <ContainerLoading />;
    }
    if (!unhappyCustomerReviews || !businessProfile) {
      return;
    }
    const { resolved, unresolved } = unhappyCustomerReviews;
    const sections = selectedTab === UnhappyCustomerTabs.Unresolved ? unresolved : resolved;
    if (sections.length === 0) {
      return (
        <Empty
          style={{
            marginTop: '20vh',
            marginBottom: '20vh',
          }}
          description="No reviews available"
        />
      );
    }
    return sections.map(section => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          key={section.title}>
          <div style={{ fontWeight: 500, paddingTop: 24, paddingBottom: 24, alignSelf: 'center' }}>
            {section.title}
          </div>
          {section.data
            .filter(review =>
              get(
                unresolvedReviewFilters,
                review.resolutionStatus || businessProfile.resolutionStages.initialStage.name,
                true,
              ),
            )
            .map(review => (
              <ReviewCard
                stages={businessProfile.resolutionStages}
                showReviewDetails={() => setSelectedReview(review)}
                showAddNotes={stage => setAddNotesPopupReviewData({ stage, reviewUid: review.uid })}
                key={review.uid}
                review={review}
              />
            ))}
        </div>
      );
    });
  };

  const renderFilterOptions = () => {
    if (!businessProfile) {
      return;
    }
    return (
      <Menu>
        {businessProfile.resolutionStages.stages
          .filter(stage => !stage.isResolvedStage)
          .map(stage => (
            <Menu.Item>
              <Checkbox
                value={stage.name}
                checked={get(unresolvedReviewFilters, stage.name, true)}
                onChange={e =>
                  setUnresolvedReviewFilters({
                    ...unresolvedReviewFilters,
                    [stage.name]: e.target.checked,
                  })
                }>
                {stage.name}
              </Checkbox>
            </Menu.Item>
          ))}
      </Menu>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.Grey100,
      }}>
      {addNotesPopupReviewData &&
        addNotesPopupReviewData.reviewUid &&
        businessProfile && (
          <Modal
            title="Add remarks"
            onCancel={() => {
              setAddNotesPopupReviewData({ reviewUid: undefined, stage: undefined });
            }}
            footer={[
              <Button
                key="back"
                disabled={!businessProfile.skippableRemarks}
                onClick={() => {
                  markReview(addNotesPopupReviewData.reviewUid!, addNotesPopupReviewData.stage!);
                  setAddNotesPopupReviewData({ reviewUid: undefined, stage: undefined });
                }}>
                Skip
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() =>
                  onSubmit(addNotesPopupReviewData.reviewUid!, addNotesPopupReviewData.stage!)
                }>
                Add Remarks
              </Button>,
            ]}
            visible>
            <Input.TextArea
              onChange={event => {
                setNote(event.target.value);
              }}
              rows={4}
              placeholder="Remarks"
            />
          </Modal>
        )}
      {selectedReview && (
        <Modal
          visible
          okText="Add Remarks"
          onCancel={() => setSelectedReview(undefined)}
          width={'70%'}
          onOk={() =>
            setAddNotesPopupReviewData({ reviewUid: selectedReview.uid, stage: undefined })
          }>
          <ReviewDetails onDelete={() => setSelectedReview(undefined)} review={selectedReview} />
        </Modal>
      )}
      <div
        style={{
          maxWidth: 1024,
          marginTop: 16,
          marginBottom: 16,
          display: 'flex',
          flex: 1,
          width: '100%',
          padding: 0,
        }}>
        <span style={{ alignSelf: 'center', marginRight: 8, color: 'black', fontWeight: 'bold' }}>
          Review Date:
        </span>
        <DatePicker.RangePicker
          allowClear={false}
          separator="to"
          onChange={dates => setSelectedDates(dates as [Moment, Moment])}
          defaultValue={selectedDates as [Moment, Moment]}
        />
        <Button
          onClick={downloadData}
          icon="cloud-download"
          disabled={processingDownload}
          loading={processingDownload}
          type="primary"
          style={{ marginLeft: 'auto' }}>
          Download
        </Button>
      </div>
      {renderTurnaroundTimeCards()}
      <div
        style={{
          margin: 20,
          boxShadow: '1px 1px 8px #d0d0d0',
          maxWidth: 1024,
          width: '100%',
          paddingLeft: 16,
          backgroundColor: Colors.White100,
          paddingRight: 16,
        }}>
        <Menu
          selectedKeys={[selectedTab]}
          onSelect={option => setSelectedTab(option.key as UnhappyCustomerTabs)}
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
          mode="horizontal">
          <Menu.Item key={UnhappyCustomerTabs.Unresolved}>
            <span>
              {UnhappyCustomerTabs.Unresolved}{' '}
              {unhappyCustomerReviews
                ? `(${unhappyCustomerReviews.unresolved.reduce(
                    (acc, item) => acc + item.data.length,
                    0,
                  )})`
                : ''}
            </span>
          </Menu.Item>
          <Menu.Item key={UnhappyCustomerTabs.Resolved}>
            <span>
              {UnhappyCustomerTabs.Resolved}{' '}
              {unhappyCustomerReviews
                ? `(${unhappyCustomerReviews.resolved.reduce(
                    (acc, item) => acc + item.data.length,
                    0,
                  )})`
                : ''}
            </span>
          </Menu.Item>
        </Menu>
        {selectedTab === UnhappyCustomerTabs.Unresolved && (
          <div
            style={{
              fontSize: 16,
              marginRight: 24,
              marginTop: 8,
              textAlign: 'right',
              fontWeight: 500,
            }}>
            <Dropdown overlay={renderFilterOptions()}>
              <a className="ant-dropdown-link" href="#">
                <Icon type="filter" /> Filter
              </a>
            </Dropdown>
          </div>
        )}
        <div style={{ marginLeft: 24, marginRight: 24 }}>{renderReviews()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { currentListingUid, businessProfile, employeeListings } = state.BusinessProfile;
  const { averageTurnaroundTime, unhappyCustomerReviews, loading } = state.Home;
  return {
    currentListingUid,
    unhappyCustomerReviews,
    loading,
    businessProfile,
    averageTurnaroundTime,
    employeeListings,
  };
};

export default connect(
  mapStateToProps,
  {
    getUnhappyCustomerReviews,
    addNoteToReview,
    markReviewAs,
    getAverageTurnaroundTime,
  },
)(UnhappyCustomersContainer);
